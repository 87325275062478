import React from 'react'
import { Layout, SEO } from '@components'
import causes from '@data/causes.json'
import skills from '@data/skills.json'
import './causes.scss'

const CausesPage = () => {
  return (
    <Layout>
      <SEO title="Causes & Mentorship" />
      <h1 className="txt-center">Causes &amp; Mentorship</h1>
      <p className="txt-center txt-heavy">
        Below are some of the causes I support.
      </p>
      <ul>
        {causes.map(({ name, url, description }) => (
          <li key={name}>
            <a href={url} className="txt-heavy">
              {name}
            </a>{' '}
            – {description}
          </li>
        ))}
      </ul>
      <h2 className="txt-center txt-rule">
        <span className="txt-clipping">Mentorship with Brad</span>
      </h2>
      <p className="txt-center push--bottom">
        Are you seeking guidance as a web developer? Maybe I can help.
      </p>
      <p className="txt-heavy txt-center">
        Areas of Mentorship and Teaching Assistance:
      </p>
      <ul className="skills push-large--bottom">
        {skills.map(({ name }) => (
          <li key={name}>{name}</li>
        ))}
      </ul>
      <p className="txt-center flush--bottom">
        <a href="https://mentorcruise.com/mentor/BradZickafoose/">
          Apply for Mentorship
        </a>
        &nbsp;→
      </p>
    </Layout>
  )
}
export default CausesPage
